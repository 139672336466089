.sidebar {
  width: 250px;
  transition: 0.3s;
}

.content-area {
  overflow: auto;
}

.logo {
  font-family: 'DM Serif Display', serif;
  text-align: center;
}